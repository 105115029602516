<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import Vue from 'vue';
import VueChatScroll from 'vue-chat-scroll';
import EditFile from '@/shared/components/EditFile.vue';

Vue.use(VueChatScroll);

const store = {
  state: {
    pdfInfo: null,
    loading: false,
    pdf: true,
    mainSelection: 'learning_cards',
    shared: false,
  },
};
export default {
  components: {
    EditFile,
  },
  data() {
    return {
      message: '',
      store,
    };
  },
  methods: {
    getFile() {
      const getFilePath = '/WebOpenaiGetPdfDetails.php';
      const data = new FormData();
      data.append('file_id', this.$route.params.id);

      fetch(server + getFilePath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        }
        if (json.name !== '') {
          store.state.pdfInfo = json;
          if (json.groups.length > 0 || json.departments.length > 0) {
            store.state.shared = true;
          } else {
            store.state.shared = false;
          }
        }
      });
    },
    goTo(mainSelect) {
      switch (mainSelect) {
        default:
        case 'learning_cards':
          if (store.state.mainSelection !== 'learning_cards') {
            store.state.mainSelection = 'learning_cards';
            this.$router.push(`/file-ai/${this.$route.params.id}/learning-cards`);
          }
          break;
        case 'evaluation':
          if (store.state.mainSelection !== 'evaluation') {
            store.state.mainSelection = 'evaluation';
            this.$router.push(`/file-ai/${this.$route.params.id}/evaluation`);
          }
          break;
        case 'view':
          if (store.state.mainSelection !== 'view') {
            store.state.mainSelection = 'view';
            this.$router.push(`/file-ai/${this.$route.params.id}/view`);
          }
          break;
      }
    },
    openEditFile() {
      const addCardWindow = document.querySelector('.add-item');
      addCardWindow.classList.toggle('show');
      if (addCardWindow.classList.value.includes('hide')) {
        addCardWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addCardWindow.classList.toggle('hide');
        }, 300);
      }
    },
  },
  beforeMount() {
    this.getFile();
  },
  beforeUpdate() {
    if (this.$route.name.includes('learning')) {
      store.state.mainSelection = 'learning_cards';
    }
  },
};
</script>
<template lang="html">
  <div>
    <b-row class="chat">
      <b-col md="12" style="margin-bottom: 24px">
        <div class="title-0-medium-28 basic-1"
             style="margin-right: 40px;display: inline-flex;align-items: center;">
         {{ store.state.pdfInfo && store.state.pdfInfo.name ? store.state.pdfInfo.name  : ''}}
        </div>
        <unicon name="estate" width="16" height="16" fill="#1694D0"
                style="cursor: pointer;margin-top: -12px"
                v-on:click="$router.push('/file-ai')"
                v-if="store.state.parentName !== '' || store.state.depName !== ''"></unicon>

        <unicon name="angle-right" width="20" height="20" fill="#828282"/>
        <div class="d-inline">
          {{ store.state.pdfInfo && store.state.pdfInfo.name ? store.state.pdfInfo.name  : ''}}
        </div>
      </b-col>
    </b-row>

    <b-row style="margin: 0 -12.5px!important;">
      <b-col sm="12">
        <div class="card" style="height: fit-content; position: unset; margin-bottom: 24px">
          <div>
            <div class="file-details-header">
              <div class="main-select">
                <div :class="'subhead-reg-14 basic-1 selector learning-cards '
                       + (store.state.mainSelection === 'learning_cards' ? 'active' : '')"
                     v-on:click="goTo('learning_cards');">
                  <img src="/media/learning_cards_green.svg" alt="" style="width: 20px;
                   margin-top: -6px"/>
                  {{ $t("TAGS.CARDS") }}
                </div>
                <div :class="'subhead-reg-14 basic-1 selector evaluation '
                       + (store.state.mainSelection === 'evaluation' ? 'active' : '')"
                     v-on:click="goTo('evaluation');">

                  <unicon height="20" width="20" name="file-check-alt"
                          fill="#1694D0"></unicon>
                  {{ $t("GENERAL.EVALUATION") }}
                </div>
                <div :class="'subhead-reg-14 basic-1 selector view '
            + (store.state.mainSelection === 'view' ? 'active' : '')"
                     v-on:click="goTo('view');"
                >

                  <unicon height="20" width="20"
                          name="eye" fill="#FDAB3D"></unicon>
                  {{ $t("GENERAL.VIEW") }}
                </div>
              </div>
              <div class="share-button" v-if="store.state.mainSelection === 'evaluation'"
              v-on:click="() => openEditFile()">
                <div v-if="store.state.shared">
                  <unicon height="16" width="16" name="check-square"
                          fill="#1694D0"></unicon>
                  {{ $t("FILE_AI.SHARED") }}
                </div>
                <div v-if="!store.state.shared">
                  <unicon height="16" width="16" name="square-full"
                          fill="#828282"></unicon>
                  {{ $t("FILE_AI.UNSHARED") }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-content">
          <transition name="fade-in-up" mode="out-in">
            <router-view/>
          </transition>
        </div>
        </div>
      </b-col>
    </b-row>
    <div class="add-item hide">
      <EditFile :key="store.state.pdfInfo ? store.state.pdfInfo.id : 0"
                :file="store.state.pdfInfo"
                :callback="this.getFile"
      >
      </EditFile>
    </div>
  </div>
</template>
<style lang="scss">
.file-details-header {
  border-bottom: 1px solid #E0E0E0!important;
  padding: 8px;
}
.card-content{
  padding: 8px;
  height: fit-content;
}
.file-details-header .main-select {
  border-radius: 8px;
  display: inline-flex;
  margin-right: 24px;
}
.share-button{
  display: inline-flex;
  margin-left: auto;
  float: right;
}
.share-button svg{
  margin-top: -4px;
}
.file-details-header .main-select .selector, .share-button {
  background: #F5F6F8;
  padding: 10px 12px;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 10px!important;
}
.main-select .unicon {
  height: 20px;
}
.main-select .unicon svg{
  margin-top: -4px;
}
.number {
  display: inline-flex;
  padding: 4px 8px;
  border-radius: 6px;
  height: fit-content;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}
.main-select .selector.view .number{
  background: rgba(253, 171, 61, 0.1);
  color: #F48C00;
}
.main-select .selector.evaluation .number{
  background: rgba(67, 83, 255, 0.1);
  color: rgba(67, 83, 255, 1);
}
.main-select .selector.learning-cards .number{
  background: rgba(0, 202, 114, 0.1);
  color: #00CA72;
}
.main-select .selector.view.active, .main-select .selector.view:hover{
  background-color: #FFF6D6;
}
.main-select .selector.courses.active, .main-select .selector.courses:hover{
  background-color: #FFE9E9;
}
.main-select .selector.evaluation.active, .main-select .selector.evaluation:hover{
  background-color: #E8F4FA;
}
.main-select .selector.learning-cards.active, .main-select .selector.learning-cards:hover{
  background-color: #DBFFEF;
}
</style>
